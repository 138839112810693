import React, {useState} from "react"
import "../../styles/image-right-with-content-toggle.scss"

export default function ImageRightWithContentToggle({ data, slug, location }) {
    var toggle = false
    const[toggleText, setToggle] = useState(toggle)

    function openToggle() {
        toggle = toggleText
        toggle = !toggle
        return setToggle(toggle)
    }
    return (
        <div className="image-right-with-content-toggle-wrapper">
            <div className="image-right-with-content-toggle">
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
                <div className="text-content-wrapper">
                    <h3 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle}</h3>
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title book">{data?.title}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="toggle-wrapper">
                        <h2 className={"title title__toggle " + (toggleText ? 'minus' : '')} onClick={() => openToggle()}>{data?.toggle?.title}</h2>
                        <div className={"description description__toggle " + (toggleText ? 'opened' : '')} dangerouslySetInnerHTML={{__html: data?.toggle?.content}}></div>
                    </div>
                    {data?.button &&
                    <a data-sal="fade"
                       data-sal-duration="1s"
                       data-sal-easing="ease" className="button" href={data?.button?.url} target={data?.button?.target}>{data?.button?.title}</a>
                    }
                </div>
            </div>
            {data?.contentBelow?.content &&
            <div className="after-image-content">
                <h3 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="subtitle blue">{data?.contentBelow?.subtitle}</h3>
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="title book">{data?.contentBelow?.title}</h2>
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.contentBelow?.content}}></div>
            </div>
            }

        </div>
    )
}
