import React from "react"
import "../../styles/blog.scss"
import BlogList from "./BlogList";

export default function Blog({ data, slug, location }) {
    console.log('blog', data)
    return (
        <div className="blog-wrapper">
            <h2 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="title book">{data?.title}</h2>
            <BlogList />
        </div>
    )
}
