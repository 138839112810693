import React, {useState} from "react"
import "../../styles/step-tabs.scss"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export default function StepTabs({ data, slug, location }) {
    var tabs = Array(data?.tabs?.length).fill(false)
    tabs[0] = true
    const[tabsMobile, setTabsMobile] = useState(tabs)

    const widthOfTabs = (1 / data?.tabs?.length) * 100;

    function openTab(index) {
        tabs = tabsMobile
        var openedTab = !tabs[index]
        tabs.fill(false)
        tabs[index] = openedTab
        return setTabsMobile([...tabs])
    }
    return (
        <div className="step-tabs-wrapper">
            <h2 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="title main-title">{data?.title}</h2>
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="tabs-desktop">
                <Tabs>
                    <TabList>
                        {data?.tabs?.map(tab => {
                            return (
                                <Tab style={{width: widthOfTabs + '%'}}>{tab?.title}</Tab>
                            )
                        })}
                    </TabList>
                    {data?.tabs?.map(tab => {
                        return (
                            <TabPanel>
                                <img src={tab?.image?.localFile?.publicURL} alt={tab?.image?.altText} loading="lazy" />
                                <div className="text-content-wrapper">
                                    <div className="text-content">
                                        <h2 className="title">{tab?.title}</h2>
                                        <div className="description" dangerouslySetInnerHTML={{__html: tab?.content}}></div>
                                    </div>
                                </div>
                            </TabPanel>
                        )
                    })}
                </Tabs>
            </div>
            <div className="tabs-mobile">
                {data?.tabs?.map((tab, index) => {
                    return (
                        <div className={"tab " + (!tabsMobile[index] ? 'disabled' : '')}>
                            <h2 onClick={() => openTab(index)}>{tab?.title}</h2>
                            <div className="content">
                                <img src={tab?.image?.localFile?.publicURL} alt={tab?.image?.altText} loading="lazy" />
                                <div className="text-content">
                                    <h2 className="title">{tab?.title}</h2>
                                    <div className="description description__toggle" dangerouslySetInnerHTML={{__html: tab?.content}}></div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
