import React, {useState} from "react"
import "../../styles/boxed-content-with-images-reversed.scss"

export default function BoxedContentWithImagesReversed({ data, slug, location }) {
    var toggleArray = Array(2).fill(false)
    const[toggleText, setToggle] = useState(toggleArray)

    function openToggle(index) {
        toggleArray = toggleText
        toggleArray[index] = !toggleArray[index]
        return setToggle([...toggleArray])
    }

    return (
        <div className="boxed-content-with-images-reversed-wrapper">
            <div className="box-wrapper">
                <div className="boxed-content-with-images">
                    <div className="first-col">
                        <div className={"text-content-wrapper " + (data?.content1WithoutToggleOnMobile ? 'full-text-mobile' : '')}>
                            {data?.subtitle1 &&
                            <h3 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle1}</h3>
                            }
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title book">{data?.title1}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content1}}></div>
                        </div>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className={"toggle-wrapper " + (data?.content1WithoutToggleOnMobile ? 'hide-toggle' : '')}>
                            <h2 className={"title__toggle blue " + (toggleText[0] ? 'minus' : '')} onClick={() => openToggle(0)}>{data?.subtitle1}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            <div className={"description__toggle "  + (toggleText[0] ? 'opened' : '')} dangerouslySetInnerHTML={{__html: data?.content1}}></div>
                        </div>
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" src={data?.image1?.localFile?.publicURL} alt={data?.image1?.altText} loading="lazy" />
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="image-mobile" src={data?.image2?.localFile?.publicURL} alt={data?.image2?.altText} loading="lazy" />
                    </div>
                    <div className="second-col">

                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" src={data?.image2?.localFile?.publicURL} alt={data?.image2?.altText} loading="lazy" />
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="image-mobile" src={data?.image1?.localFile?.publicURL} alt={data?.image1?.altText} loading="lazy" />
                        <div className="text-content-wrapper">
                            {data?.subtitle2 &&
                            <h3 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle2}</h3>
                            }
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title book">{data?.title2}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content2}}></div>
                            {data?.toggle1?.title &&
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="toggle-wrapper">
                                <h2 data-sal="fade"
                                    data-sal-delay="100"
                                    data-sal-duration="1s"
                                    data-sal-easing="ease-in" className={"title " + (toggleText[1] ? 'minus' : '')} onClick={() => openToggle(1)}>{data?.toggle1?.title}</h2>
                                <div data-sal="fade"
                                     data-sal-delay="100"
                                     data-sal-duration="1s"
                                     data-sal-easing="ease-in" className={"description " + (toggleText[1] ? 'opened' : '')}
                                     dangerouslySetInnerHTML={{__html: data?.toggle1?.content}}></div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {data?.afterBoxContent?.content &&
            <div className="after-box-content-wrapper">
                {data?.afterBoxContent?.subtitle &&
                <h3 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="subtitle">{data?.afterBoxContent?.subtitle}</h3>
                }
                {data?.afterBoxContent?.title &&
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="title blue">{data?.afterBoxContent?.title}</h2>
                }
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.afterBoxContent?.content}}></div>
                {data?.afterBoxContent?.button &&
                <a data-sal="fade"
                   data-sal-duration="1s"
                   data-sal-easing="ease" className="button "
                   href={data?.afterBoxContent?.button?.url}>{data?.afterBoxContent?.button?.title}</a>
                }
            </div>
            }
        </div>
    )
}
