import React, {useState} from "react"
import "../../styles/history-block.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation} from "swiper";

export default function HistoryBlock({ data, slug, location }) {
    const[currentIndex, setCurrentIndex] = useState(0)

    function changeIndex(newIndex) {
        return setCurrentIndex(newIndex)
    }

    return (
        <div className="history-block-wrapper">
            <div className="history-block">
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" src={data?.yearsSlider[currentIndex]?.image?.localFile?.publicURL} alt={data?.yearsSlider[currentIndex]?.image?.altText} loading="lazy" />
                <div className="history-second-col">
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title mobile">{data?.title}</h2>
                    <div className="years-swiper-wrapper">
                        <div className="overlay"></div>
                        <div className="years-swiper">
                            <span className="nav-arrow years-slider-prev"></span>
                            <Swiper
                                modules={[Navigation]}
                                direction={"vertical"}
                                slidesPerView={"auto"}
                                spaceBetween={0}
                                centeredSlides={true}
                                slideToClickedSlide={true}
                                navigation={{
                                    prevEl: '.years-slider-prev',
                                    nextEl: '.years-slider-next',
                                }}
                                onSlideChange={(swiper) => changeIndex(swiper.realIndex)}
                            >
                                {data?.yearsSlider && data?.yearsSlider?.map((year) => {
                                    return (
                                        <SwiperSlide>
                                            <h2>{year.year}</h2>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            <span className="nav-arrow years-slider-next"></span>
                        </div>
                    </div>
                    <div className="text-content-wrapper">
                        <h2 data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="title title-xl">{data?.title}</h2>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.yearsSlider[currentIndex]?.content}}></div>
                    </div>
                </div>
            </div>
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="content-after-slider description description-large" dangerouslySetInnerHTML={{__html: data?.contentAfterSlider}}></div>
        </div>
    )
}
