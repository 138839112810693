import React, { useState } from "react"
import "../../styles/slider.scss"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Slider({ data, slug, location }) {
    const [sliderBG, setSliderBG] = useState(data?.backgroundImage?.localFile?.publicURL);

    return (
        <div className="slider-wrapper">
            <h2 className="title">{data?.title}</h2>
            <div className="slider" style={{backgroundImage: "url(" + sliderBG + ")"}}>
                <div className="overlay"></div>
                <Swiper
                    data-sal="fade"
                    data-sal-duration="1s"
                    data-sal-easing="ease"
                    modules={[Pagination, Navigation]}
                    centeredSlides={true}
                    initialSlide={1}
                    slidesPerView={1}
                    // spaceBetween={50}
                    slideToClickedSlide={true}
                    pagination={{clickable: true}}
                    navigation={{clickable: true,
                                nextEl: '.swiper-button-next-custom',
                                prevEl: '.swiper-button-prev-custom',
                            }}

                    breakpoints={{
                        480: {
                            width: 400,
                        },
                        768: {
                            // spaceBetween: 80,
                            slidesPerView: "auto",
                            width: null,
                            navigation: {clickable: false},
                        },
                    }}
                    onSlideChange={(swiperCore) => {
                        const {
                          activeIndex,
                        } = swiperCore;

                        // change slider background based on active slide
                        setSliderBG(data?.sliderCards[activeIndex]?.images?.localFile?.publicURL)
                    }}
                >
                    {data?.sliderCards?.map(slider => {
                        const image = getImage(slider?.images?.localFile?.childImageSharp?.gatsbyImageData)

                        return (
                            <SwiperSlide>
                                <h2 className="title title-inactive">{slider?.title}</h2>
                                <GatsbyImage image={image} alt={slider?.images?.altText} loading="lazy" formats={["webp", "auto"]} />

                                <div className="text-content-wrapper">
                                    <h2 className="title">{slider?.title}</h2>
                                    <div className="description" dangerouslySetInnerHTML={{__html: slider?.content}}></div>
                                    <a className="button" href={slider?.button?.url} target={slider?.button?.target}>
                                        {slider?.button?.title}
                                    </a>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                <div className="swiper-button-prev-custom"></div>
                <div className="swiper-button-next-custom"></div>
            </div>
        </div>
    )
}
