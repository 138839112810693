import React from "react"
import "../../styles/map.scss"
import USAMap from "@jlev/react-usa-map"
import {graphql, navigate, StaticQuery} from "gatsby"

import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation
} from "react-simple-maps";


export default function Map({ data, slug, location }) {
    console.log('map ', data)

    // var statesCustomConfig = () => {
    //     return {
    //         "AK": {
    //         },
    //         "NJ": {
    //             clickHandler: (event) => console.log('Custom handler for NJ', event.target.dataset)
    //         },
    //         "NY": {
    //         }
    //     };
    // };

    // customize={statesCustomConfig()}
    const states = {
      "AL": "Alabama",
      "AK": "Alaska",
      "AS": "American Samoa",
      "AZ": "Arizona",
      "AR": "Arkansas",
      "CA": "California",
      "CO": "Colorado",
      "CT": "Connecticut",
      "DE": "Delaware",
      "DC": "Washington DC",
      "FM": "Federated States Of Micronesia",
      "FL": "Florida",
      "GA": "Georgia",
      "GU": "Guam",
      "HI": "Hawaii",
      "ID": "Idaho",
      "IL": "Illinois",
      "IN": "Indiana",
      "IA": "Iowa",
      "KS": "Kansas",
      "KY": "Kentucky",
      "LA": "Louisiana",
      "ME": "Maine",
      "MH": "Marshall Islands",
      "MD": "Maryland",
      "MA": "Massachusetts",
      "MI": "Michigan",
      "MN": "Minnesota",
      "MS": "Mississippi",
      "MO": "Missouri",
      "MT": "Montana",
      "NE": "Nebraska",
      "NV": "Nevada",
      "NH": "New Hampshire",
      "NJ": "New Jersey",
      "NM": "New Mexico",
      "NY": "New York",
      "NC": "North Carolina",
      "ND": "North Dakota",
      "MP": "Northern Mariana Islands",
      "OH": "Ohio",
      "OK": "Oklahoma",
      "OR": "Oregon",
      "PW": "Palau",
      "PA": "Pennsylvania",
      "PR": "Puerto Rico",
      "RI": "Rhode Island",
      "SC": "South Carolina",
      "SD": "South Dakota",
      "TN": "Tennessee",
      "TX": "Texas",
      "UT": "Utah",
      "VT": "Vermont",
      "VI": "Virgin Islands",
      "VA": "Virginia",
      "WA": "Washington",
      "WV": "West Virginia",
      "WI": "Wisconsin",
      "WY": "Wyoming"
    }

    console.log(states.length)

    const slugify = str =>
      str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

    const mapHandler = (event, blackList) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      if (blackList.includes(states[event.target.dataset.name]))
          return;
      navigate(`/${slugify(states[event.target.dataset.name])}`)
    }

    const stateHandler = (event) => {
      navigate(`/${slugify(states[event.target.value])}`)
    }

    const navigateToState = (event, blackList) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      if (Object.values(blackList).includes(event.target.dataset.name)) {
        return;
      }

      navigate(`/${slugify(event.target.dataset.name)}`)
    }

    const annotationOver = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = document.querySelector('path[data-name*="' + event.target.dataset.name + '"]');

      if (path) {
        path.classList.add('active');
      }
    }

    const annotationOut = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = document.querySelector('path[data-name*="' + event.target.dataset.name + '"]');

      if (path) {
        path.classList.remove('active');
      }
    }

    const markerOver = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = document.querySelector('path[data-name*="' + event.target.dataset.name + '"]');

      if (path) {
        path.classList.add('active');
      }

      const text = event.currentTarget.querySelector('text');

      if (text) {
        text.classList.add('active');
      }
    }

    const markerOut = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = document.querySelector('path[data-name*="' + event.target.dataset.name + '"]');

      if (path) {
        path.classList.remove('active');
      }

      const text = event.currentTarget.querySelector('text');

      if (text) {
        text.classList.remove('active');
      }
    }

    const pathOver = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = event.currentTarget;

      if (path) {
        path.classList.add('active');
      }

      const text = document.querySelector('text[data-name*="' + event.target.dataset.name + '"][data-marker]');

      if (text) {
        text.classList.add('active');
      }
    }

    const pathOut = (event) => {
      if (event.target.dataset.name === 'District of Columbia') {
        event.target.dataset.name = 'Washington DC';
      }

      const path = event.currentTarget;

      if (path) {
        path.classList.remove('active');
      }

      const text = document.querySelector('text[data-name*="' + event.target.dataset.name + '"][data-marker]');

      if (text) {
        text.classList.remove('active');
      }
    }

    const allStates = [
      { "id": "AL", "val": "01" },
      { "id": "AK", "val": "02" },
      { "id": "AS", "val": "60" },
      { "id": "AZ", "val": "04" },
      { "id": "AR", "val": "05" },
      { "id": "CA", "val": "06" },
      { "id": "CO", "val": "08" },
      { "id": "CT", "val": "09" },
      { "id": "DE", "val": "10" },
      { "id": "DC", "val": "11" },
      { "id": "FL", "val": "12" },
      { "id": "FM", "val": "64" },
      { "id": "GA", "val": "13" },
      { "id": "GU", "val": "66" },
      { "id": "HI", "val": "15" },
      { "id": "ID", "val": "16" },
      { "id": "IL", "val": "17" },
      { "id": "IN", "val": "18" },
      { "id": "IA", "val": "19" },
      { "id": "KS", "val": "20" },
      { "id": "KY", "val": "21" },
      { "id": "LA", "val": "22" },
      { "id": "ME", "val": "23" },
      { "id": "MH", "val": "68" },
      { "id": "MD", "val": "24" },
      { "id": "MA", "val": "25" },
      { "id": "MI", "val": "26" },
      { "id": "MN", "val": "27" },
      { "id": "MS", "val": "28" },
      { "id": "MO", "val": "29" },
      { "id": "MT", "val": "30" },
      { "id": "NE", "val": "31" },
      { "id": "NV", "val": "32" },
      { "id": "NH", "val": "33" },
      { "id": "NJ", "val": "34" },
      { "id": "NM", "val": "35" },
      { "id": "NY", "val": "36" },
      { "id": "NC", "val": "37" },
      { "id": "ND", "val": "38" },
      { "id": "MP", "val": "69" },
      { "id": "OH", "val": "39" },
      { "id": "OK", "val": "40" },
      { "id": "OR", "val": "41" },
      { "id": "PW", "val": "70" },
      { "id": "PA", "val": "42" },
      { "id": "PR", "val": "72" },
      { "id": "RI", "val": "44" },
      { "id": "SC", "val": "45" },
      { "id": "SD", "val": "46" },
      { "id": "TN", "val": "47" },
      { "id": "TX", "val": "48" },
      { "id": "UM", "val": "74" },
      { "id": "UT", "val": "49" },
      { "id": "VT", "val": "50" },
      { "id": "VA", "val": "51" },
      { "id": "VI", "val": "78" },
      { "id": "WA", "val": "53" },
      { "id": "WV", "val": "54" },
      { "id": "WI", "val": "55" },
      { "id": "WY", "val": "56" }
    ];

    const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

    const offsets = {
      VT: [50, -8],
      NH: [34, 2],
      MA: [30, -1],
      RI: [28, 2],
      CT: [35, 10],
      NJ: [34, 1],
      DE: [33, 0],
      MD: [47, 10],
      DC: [49, 21],
      // FL: [0, 0],
      // HI: [0, 0],
      // MI: [0, 0],
      // LA: [-20, 0],
    };

    return (
        <StaticQuery
        query={graphql`
        query StatesQuery {
          allWpPage(filter: {template: {templateName: {eq: "State"}}}) {
            edges {
              node {
                id
                title
                uri
              }
            }
          }
        }
      `}

        render={dataQuery => {
            let blackList = Object.values(states);
            let blackListKeys = Object.keys(states);

            if (typeof document !== `undefined`) {

                console.log(dataQuery?.allWpPage)

                dataQuery?.allWpPage?.edges?.map(stateItem => {
                    let state = stateItem?.node?.title;
                    if (Object.values(states).includes(state)) {
                      console.log(state, 'black')
                        let index = blackList.indexOf(state)
                        blackList.splice(index, 1);
                        blackListKeys.splice(index, 1)
                    }
                })

                console.log(blackList, 'black')

                // blackListKeys.forEach(keyItem => {
                //     let style = document.createElement('style');
                //     style.textContent = `
                //   .map-wrapper .map path[data-name="` + keyItem + `"]:hover {
                //     fill: rgb(211, 211, 211);
                //   }
                  
                //   .map-wrapper .map g#` + keyItem + `:hover > text {
                //     fill: inherit;
                //   }
                // `;
                //     document.head.appendChild(style);

                // })
            }

            return (
                <div className="map-wrapper">
                    <div className="text-content-wrapper">
                        <h2 data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="title book">{data?.title}</h2>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="mobile-dropdown">
                            <select name="states" onChange={stateHandler}>
                                <option value="" selected>Select a state...</option>
                                {states && Object.keys(states).map((key, index) => {
                                    if (!blackList?.includes(states[key])) {
                                        return (
                                            <option value={key}>{states[key]}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="map">

                        <ComposableMap projection="geoAlbersUsa">
                          <Geographies geography={geoUrl}>
                            {({ geographies }) => (
                              <>
                                {geographies.map(geo => {
                                  // console.log(geo, 'geo')
                                return (
                                  <Geography
                                    key={geo.rsmKey}
                                    stroke="#FFF"
                                    geography={geo}
                                    fill="#DDD"
                                    onClick={(event) => navigateToState(event, blackList)}
                                    data-name={geo?.properties?.name}
                                    onMouseEnter={pathOver}
                                    onMouseLeave={pathOut}
                                  />
                                )})}
                                {geographies.map(geo => {
                                  const centroid = geoCentroid(geo);
                                  const cur = allStates.find(s => s.val === geo.id);
                                  return (
                                    <g key={geo.rsmKey + "-name"}>
                                      {cur &&
                                        centroid[0] > -160 &&
                                        centroid[0] < -67 &&
                                        (Object.keys(offsets).indexOf(cur.id) === -1 ? (
                                          <Marker coordinates={centroid} onClick={(event) => navigateToState(event, blackList)} data-name={geo?.properties?.name} onMouseEnter={markerOver} onMouseLeave={markerOut}>
                                            <text y="2" fontSize={14} textAnchor="middle" data-name={geo?.properties?.name} data-marker="1">
                                              {cur.id}
                                            </text>
                                          </Marker>
                                        ) : (
                                          <Annotation
                                            subject={centroid}
                                            dx={offsets[cur.id][0]}
                                            dy={offsets[cur.id][1]}
                                            onClick={(event) => navigateToState(event, blackList)}
                                            onMouseEnter={annotationOver}
                                            onMouseLeave={annotationOut}
                                            data-name={geo?.properties?.name}
                                          >
                                            <text x={4} fontSize={14} alignmentBaseline="middle" data-name={geo?.properties?.name}>
                                              {cur.id}
                                            </text>
                                          </Annotation>
                                        ))}
                                    </g>
                                  );
                                })}
                              </>
                            )}
                          </Geographies>
                        </ComposableMap>
                    </div>

                </div>
            )
        }}
        />
    )
}
