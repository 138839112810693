import React, {useState} from "react"
import "../../styles/image-left-with-space.scss"

export default function ImageLeftWithSpace({ data, slug, location }) {
    const[toggle, setToggle] = useState(false)

    return (
        <div className="image-left-with-space-wrapper">
            <img data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
            <div className="text-content-wrapper">
                {data?.subtitle &&
                <h3 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle}</h3>
                }
                {data?.title &&
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="title book">{data?.title}</h2>
                }
                {data?.content &&
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description description-desktop" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                }
                {data?.toggle?.title && data?.toggle.content &&
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="toggle-wrapper">
                    <h2 className={"title title__toggle " + (toggle ? 'minus' : '')}
                        onClick={() => setToggle(!toggle)}>{data?.toggle?.title}</h2>
                    <div className={"description description__toggle " + (toggle ? 'opened' : '')}
                         dangerouslySetInnerHTML={{__html: data?.toggle?.content}}></div>
                </div>
                }
            </div>
        </div>
    )
}
