import React, {useEffect, useRef, useState} from "react"
import "../../styles/projects-list.scss"
import {graphql, StaticQuery, Link} from "gatsby";
import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import ReactPlayer from "react-player";
import FsLightbox from 'fslightbox-react';

export default function ProjectsList() {
  const showReadMore = Array().fill(false)
  const [readMore, setReadMore] = useState(showReadMore)
  let cardsPerLoad = 6;
  const [currentCardsCount, setCurrentCardsCount] = useState(12)
  const [lightbox, setLightbox] = useState([]);

  const lightboxHandler = (index) => {
    var newLightbox = lightbox
    newLightbox[index] = !newLightbox[index]
    return setLightbox([...newLightbox])
  }

    React.useEffect(() => {
      for (var i = 0; i < document.getElementsByClassName("project-description").length; i++) {
          showReadMore[i] =
              document.getElementsByClassName("project-description")[i].scrollHeight > document.getElementsByClassName("project-description")[i].clientHeight
      }
      setReadMore([...showReadMore])
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query ProjectsList {
          allWpProject {
            edges {
              node {
                id
                uri
                title
                project {
                  title
                  subtitle
                  previewContent
                  projectGallery {
                    image {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                    video
                  }
                }
                featuredImage {
                  node {
                    altText
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      `}

      render={data => {
        const projects = data?.allWpProject?.edges

        const handleMore = (event) => {
          const button = event.currentTarget
          const description = button.previousSibling

          description.classList.toggle('opened')

          if (button.innerHTML === 'Read More +') {
            button.innerHTML = 'Read Less -'
          } else {
            button.innerHTML = 'Read More +'
          }
        }

        const createProjectLightbox = (project) => {
          var gallery = []

          gallery.push(project?.featuredImage?.node?.localFile?.publicURL)
          {project?.project?.projectGallery && project?.project?.projectGallery?.map((media) => {
            if (media?.video) {
              gallery.push(media?.video)
            }
            else {
              gallery.push(media?.image?.localFile?.publicURL)
            }
          })}

          return gallery
        }

          return (
              <div className="projects-list-wrapper">
                <div className="projects-list">
                    {projects && projects?.slice(0, currentCardsCount).map((projectItem, index) => {
                        var projectIndex = index
                        var project = projectItem.node
                        var lightboxGallery = createProjectLightbox(project)
                        console.log(lightboxGallery)

                        return (
                            <div className="project-card">
                                <div className="preview-image-wrapper">
                                    <FsLightbox
                                        toggler={lightbox[projectIndex]}
                                        sources={lightboxGallery}
                                    />
                                    <Swiper
                                      modules={[Pagination, Navigation]}
                                      slidesPerView={1}
                                      pagination={true}
                                      navigation
                                      onClick={() => lightboxHandler(projectIndex)}
                                    >
                                        <SwiperSlide
                                        >
                                            <img src={project?.featuredImage?.node?.localFile?.publicURL} alt={project?.featuredImage?.node?.altText} loading="lazy" />
                                        </SwiperSlide>
                                        {project?.project?.projectGallery && project?.project?.projectGallery?.map((media, index) => {
                                            return (
                                                <SwiperSlide
                                                >
                                                    {media?.video &&
                                                    <ReactPlayer
                                                        url={media?.video}
                                                        width="auto"
                                                        height="100%"
                                                    />
                                                    }
                                                    {!media?.video &&
                                                    <img src={media?.image?.localFile?.publicURL} alt={media?.image?.altText} />
                                                    }
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                </div>
                                <div className="text-content-wrapper">
                                    <h3 className="subtitle blue">{project?.project?.subtitle}</h3>
                                    <h2 className="title book">{project?.project?.title}</h2>
                                    <div className="description project-description"
                                         dangerouslySetInnerHTML={{__html: project?.project?.previewContent}}>
                                    </div>
                                    <button className="read-more" onClick={handleMore}>Read More +</button>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {(currentCardsCount < projects.length) &&
                <a className="button load-more" onClick={() => {setCurrentCardsCount(currentCardsCount + cardsPerLoad)}}>Load More</a>
                }
              </div>
        )
      }}
    />
  )
}
