import React from "react"
import "../../styles/quote.scss"

export default function Quote({ data, slug, location }) {
    return (
        <div className="quote-wrapper">
            <span data-sal="fade"
                  data-sal-delay="100"
                  data-sal-duration="1s"
                  data-sal-easing="ease-in" className="quote">“</span>
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
            <h3 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="author">{data?.title}</h3>
        </div>
    )
}
