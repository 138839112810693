import React, { useState, useEffect } from "react"
import "../../styles/boxed-content-with-images.scss"
import ReactPlayer from "react-player";

export default function BoxedContentWithImages({ data, slug, location }) {
    var toggleArray = Array(2).fill(false)
    const[toggleText, setToggle] = useState(toggleArray)

    function openToggle(index) {
        toggleArray = toggleText
        toggleArray[index] = !toggleArray[index]
        return setToggle([...toggleArray])
    }

    const [videoThumb, setVideoThumb] = useState((data?.image1?.localFile?.publicURL) ? data?.image1?.localFile?.publicURL : true)
    const [autoplay, setAutoplay] = useState(0);

    // const isBrowser = typeof window !== "undefined"
    useEffect(() => {
        const video = document.querySelector('.video-1')
        const videoMobile = document.querySelector('.video-1-m')
        const isAutoplay = data?.autoplayVideo1

        if (video && isAutoplay) {
            video.addEventListener('sal:in', ({ detail }) => {
                console.log('entering', video)
                setVideoThumb(false)
                setAutoplay(true)
            })
        }

        if (videoMobile && isAutoplay) {
            videoMobile.addEventListener('sal:in', ({ detail }) => {
                console.log('entering', videoMobile)
                setVideoThumb(false)
                setAutoplay(true)
            })
        }
    }, [])

    return (
        <div className="boxed-content-with-images-wrapper">
            <div className="box-wrapper">
                <div className="boxed-content-with-images">
                    <div className="first-col video-1">
                        {data?.video1 &&
                        <ReactPlayer
                            class="first-col-react-player"
                            url={data?.video1}
                            width="100%"
                            height="60%"
                            light={videoThumb}
                            playing="true"
                            muted={autoplay}
                        />
                        }
                        {!data?.video1 &&
                        <img data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" src={data?.image1?.localFile?.publicURL} alt={data?.image1?.altText} loading="lazy" />
                        }
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="mobile-image " src={data?.image2?.localFile?.publicURL} alt={data?.image2?.altText} loading="lazy" />
                        <div className="text-content-wrapper">
                            {data?.subtitle2 &&
                            <h3 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle2}</h3>
                            }
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title book" onClick={() => openToggle(0)}>{data?.title2}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content2}}></div>
                        </div>
                    </div>
                    <div className="second-col">
                        <div className="text-content-wrapper">
                            {data?.subtitle1 &&
                            <h3 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle1}</h3>
                            }
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title book">{data?.title1}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content1}}></div>
                            {data?.toggle1?.title &&
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="toggle-wrapper">
                                <h2 className={"title title__toggle " + (toggleText[1] ? 'minus' : '')}
                                    onClick={() => openToggle(1)}>{data?.toggle1?.title}</h2>
                                <div className={"description description__toggle " + (toggleText[1] ? 'opened' : '')}
                                     dangerouslySetInnerHTML={{__html: data?.toggle1?.content}}></div>
                            </div>
                            }
                        </div>
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" src={data?.image2?.localFile?.publicURL} alt={data?.image2?.altText} loading="lazy" />
                        {data?.video1 &&
                        <div className="video-1-m" data-sal="fade" data-sal-delay="100" data-sal-duration="1s" data-sal-easing="ease-in">
                            <ReactPlayer
                                class="first-col-react-player mobile-react-player"
                                url={data?.video1}
                                width="100%"
                                height="40vw"
                                light={videoThumb}
                                playing="true"
                                muted={autoplay}
                            />
                        </div>
                        }
                        {!data?.video1 &&
                        <img data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="mobile-image " src={data?.image1?.localFile?.publicURL} alt={data?.image1?.altText} loading="lazy" />
                        }
                    </div>
                </div>
            </div>
            <div className="after-box-content-wrapper">
                {data?.contentAfterBox?.subtitle &&
                <h3 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="subtitle blue">{data?.contentAfterBox?.subtitle}</h3>
                }
                {data?.contentAfterBox?.title &&
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className={"title " + (data?.contentAfterBox?.bookTitleStyle ? 'book' : 'blue')}>{data?.contentAfterBox?.title}</h2>
                }
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.contentAfterBox?.content}}></div>
                {data?.contentAfterBox?.button &&
                <a data-sal="fade"
                   data-sal-duration="1s"
                   data-sal-easing="ease" className="button "
                   href={data?.contentAfterBox?.button?.url}>{data?.contentAfterBox?.button?.title}</a>
                }
            </div>
        </div>
    )
}
