import React from "react"
import "../../styles/images-with-moved-content.scss"
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ImagesWithMovedContent({ data, slug, location }) {
    const rowOneImage = getImage(data?.row1?.image?.localFile?.childImageSharp?.gatsbyImageData)
    const rowTwoImage = getImage(data?.row2?.image?.localFile?.childImageSharp?.gatsbyImageData)

    return (
        <div className="images-with-moved-content-wrapper">
            <div className="first-row">
                <GatsbyImage
                    image={rowOneImage}
                    alt={data?.row1?.image?.altText}
                    data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in"
                    className="image"
                    loading="lazy"
                    formats={["webp", "auto"]}
                />
                <div className="text-content-wrapper">
                    <div className="text-content">
                        <h2 data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="title book">{data?.row1?.title}</h2>
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.row1?.content}}></div>
                        <Link data-sal="fade"
                           data-sal-duration="1s"
                           data-sal-easing="ease" className="button" to={data?.row1?.button?.url} target={data?.row1?.button?.target}>{data?.row1?.button?.title}</Link>
                    </div>
                </div>
            </div>
            <div className="second-row">

                <div className="content-wrapper">
                    <GatsbyImage
                        image={rowTwoImage}
                        alt={data?.row2?.image?.altText}
                        data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in"
                        className="image"
                        loading="lazy"
                        formats={["webp", "auto"]}
                    />
                    <div className="text-box-wrapper">
                        <div className="text-box">
                            {data?.row2?.subtitle &&
                            <h3 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="subtitle blue">{data?.row2?.subtitle}</h3>
                            }
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title book">{data?.row2?.title}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.row2?.content}}></div>
                            <a data-sal="fade"
                               data-sal-duration="1s"
                               data-sal-easing="ease" className="button" href={data?.row2?.button?.url} target={data?.row2?.button?.target}>{data?.row2?.button?.title}</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
