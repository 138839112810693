import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/blocks/Banner"
import ImagesWithMovedContent from "../components/blocks/ImagesWithMovedContent";
import TabEvents from "../components/blocks/TabEvents";
import TwoImagesWithContents from "../components/blocks/2ImagesWithContents";
import Slider from "../components/blocks/Slider";
import Quote from "../components/blocks/Quote";
import TextContent from "../components/blocks/TextContent";
import ImageRight from "../components/blocks/ImageRight";
import ImageWith2BoxContents from "../components/blocks/ImageWith2BoxContents";
import StepTabs from "../components/blocks/StepTabs";
import ImageWithContentRightMiddle from "../components/blocks/ImageWithContentRightMiddle";
import TabsWithLeftMiddleContent from "../components/blocks/TabsWithLeftMiddleContent";
import ImageContentWithButtonBelow from "../components/blocks/ImageContentWithButtonBelow";
import ImageWithContentMiddleLeft from "../components/blocks/ImageWithContentMiddleLeft";
import TextContentLeftAligned from "../components/blocks/TextContentLeftAligned";
import ImageLeftWithBoxedContent from "../components/blocks/ImageLeftWithBoxedContent";
import ImageWithLeftTopContent from "../components/blocks/ImageWithLeftTopContent";
import ImageLeft from "../components/blocks/ImageLeft";
import LogoLeft from "../components/blocks/LogoLeft";
import ContactForm from "../components/blocks/ContactForm";
import ImageLeftWithSpace from "../components/blocks/ImageLeftWithSpace";
import ImageWithRightTopContent from "../components/blocks/ImageWithRightTopContent";
import BoxedContentWithImages from "../components/blocks/BoxedContentWithImages";
import BoxedContentWithImagesReversed from "../components/blocks/BoxedContentWithImagesReversed";
import HistoryBlock from "../components/blocks/HistoryBlock";
import Map from "../components/blocks/Map";
import Projects from "../components/blocks/Projects";
import Blog from "../components/blocks/Blog";
import ImageRightWithContentToggle from "../components/blocks/ImageRightWithContentToggle";
import JoinOurTeamForm from "../components/blocks/JoinOurTeamForm";
import TextContentWithLogo from "../components/blocks/TextContentWithLogo";
import ContentRightWithToggle from "../components/blocks/ContentRightWithToggle";
import CtaBoxes from "../components/blocks/CTABoxes";

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function Page({ data, location }) {
  const page = data?.wpPage
  const blocks = page?.blocks?.layouts
  const wpSettings = data?.wp?.themeGeneralSettings?.themeSettings

  return (
    <Fragment>
      <Seo post={page} />
      <Layout location={location}>
        {blocks && blocks.length && blocks.map(block => {
          console.log(block.fieldGroupName)
          if (block && block.fieldGroupName) {
            const name = block?.fieldGroupName.replace('Page_Blocks_Layouts_', '') // get block name
              console.log(name)

            if (name === 'Banner') {
              return (
                <Banner data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
              )
            }

            if (name === 'ImagesWithMovedContent') {
                return (
                    <ImagesWithMovedContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'TabEvents') {
                return (
                    <TabEvents data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === '2ImagesWithContents') {
                return (
                    <TwoImagesWithContents data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'Slider') {
                return (
                    <Slider data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'Quote') {
                return (
                    <Quote data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'TextContent') {
                return (
                    <TextContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageRight') {
                return (
                    <ImageRight data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageLeft') {
                return (
                    <ImageLeft data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'BoxedContentWithImages') {
                return (
                    <BoxedContentWithImages data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageWith2BoxContents') {
                return (
                    <ImageWith2BoxContents data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'StepTabs') {
                return (
                    <StepTabs data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageWithContentRightMiddle') {
                return (
                    <ImageWithContentRightMiddle data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'TabsWithLeftMiddleContent') {
                return (
                    <TabsWithLeftMiddleContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageContentWithButtonBelow') {
                return (
                    <ImageContentWithButtonBelow data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageWithContentMiddleLeft') {
                return (
                    <ImageWithContentMiddleLeft data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'TextContentLeftAligned') {
                return (
                    <TextContentLeftAligned data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageLeftWithBoxedContent') {
                return (
                    <ImageLeftWithBoxedContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageWithLeftTopContent') {
                return (
                    <ImageWithLeftTopContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'LogoLeft') {
                return (
                    <LogoLeft data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ContactForm') {
                return (
                <ContactForm data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

              if (name === 'JoinOurTeamForm') {
                  return (
                  <JoinOurTeamForm data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                  )
              }

            if (name === 'ImageLeftWithSpace') {
                return (
                  <ImageLeftWithSpace data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageWithRightTopContent') {
                return (
                    <ImageWithRightTopContent data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'BoxedContentWithImagesReversed') {
                return (
                    <BoxedContentWithImagesReversed data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'HistoryBlock') {
                return (
                    <HistoryBlock data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            // if (name === 'CtaBoxes') {
            //     return (
            //         <CtaBoxes data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
            //     )
            // }

            if (name === 'Map') {
                return (
                    <Map data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'Projects') {
                return (
                    <Projects data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'Blog') {
                return (
                    <Blog data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'ImageRightWithContentToggle') {
                return (
                    <ImageRightWithContentToggle data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
                )
            }

            if (name === 'TextContentWithLogo') {
              return (
                  <TextContentWithLogo data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
              )
            }

            if (name === 'ContentRightWithToggle') {
              return (
                  <ContentRightWithToggle data={block} slug={page?.slug} location={location} key={`page-block-${name}`} />
              )
            }

            // ...etc for each block
          }

          return true
        })}

        {!blocks ? (
          <div className="page-wrapper">
            <h1>{page?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page?.content }} />
          </div>
        ) : null}

        {wpSettings && (page?.template?.templateName === 'State' || page?.template?.templateName === 'City') &&
          <CtaBoxes data={wpSettings.ctaBoxes} slug={page?.slug} location={location} key={`page-block-cta-boxes`} />
        }
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      template {
        templateName
      }
      seo {
       title
       metaDesc
       focuskw
       metaKeywords
       opengraphTitle
       opengraphDescription
       opengraphImage {
         altText
         sourceUrl
         srcSet
       }
       twitterTitle
       twitterDescription
       twitterImage {
         altText
         sourceUrl
         srcSet
       }
       canonical
       cornerstone
       schema {
         articleType
         pageType
         raw
       }
      }
      slug
      title
      content
      blocks {
        layouts {
          ... on WpPage_Blocks_Layouts_Banner {
            fieldGroupName
            subtitle
            title
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_ImagesWithMovedContent {
            fieldGroupName
            row1 {
              button {
                target
                title
                url
              }
              content
              title
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            row2 {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TabEvents {
            fieldGroupName
            tabs {
              button {
                target
                title
                url
              }
              content
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title
            }
          }
          ... on WpPage_Blocks_Layouts_2ImagesWithContents {
            content1
            content2
            button1 {
              target
              title
              url
            }
            button2 {
              target
              title
              url
            }
            fieldGroupName
            image1 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle1
            subtitle2
            title1
            title2
            video1
            autoplayVideo1
            video2
          }
          ... on WpPage_Blocks_Layouts_Slider {
            fieldGroupName
            title
            backgroundImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            sliderCards {
              button {
                target
                title
                url
              }
              content
              images {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title
            }
          }
          ... on WpPage_Blocks_Layouts_Logos {
            content
            fieldGroupName
            logos {
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TextContent {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            logo {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
          }
          ... on WpPage_Blocks_Layouts_ImageRight {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
            afterImageContent {
              title
              subtitle
              content
            }
          }
          ... on WpPage_Blocks_Layouts_HistoryBlock {
            fieldGroupName
            title
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            yearsSlider {
              year
              content
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            contentAfterSlider
          }
          ... on WpPage_Blocks_Layouts_BoxedContentWithImages {
            fieldGroupName
            content1
            content2
            contentAfterBox {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
              bookTitleStyle
            }
            image1 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            video1
            autoplayVideo1
            image2 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle1
            subtitle2
            title1
            title2
            toggle1 {
              content
              title
            }
            toggle2 {
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_ImageWith2BoxContents {
            fieldGroupName
            aboveImageContent {
              content
              subtitle
              title
            }
            afterImageContent {
              content
              title
            }
            box1 {
              content
              title
            }
            box2 {
              content
              title
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_StepTabs {
            fieldGroupName
            title
            tabs {
              content
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              stepTitle
              title
            }
          }
          ... on WpPage_Blocks_Layouts_Quote {
            content
            fieldGroupName
            title
          }
          ... on WpPage_Blocks_Layouts_ImageWithContentRightMiddle {
            content
            fieldGroupName
            title
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            afterImageContentText
          }
          ... on WpPage_Blocks_Layouts_TabsWithLeftMiddleContent {
            fieldGroupName
            tabs {
              buttonText
              content
              title
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_ImageContentWithButtonBelow {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            mobileButtonText
            buttonBelow {
              target
              title
              url
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            title
          }
          ... on WpPage_Blocks_Layouts_ImageWithContentMiddleLeft {
            content
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            title
          }
          ... on WpPage_Blocks_Layouts_TextContentLeftAligned {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            subtitle
            title
          }
          ... on WpPage_Blocks_Layouts_ImageLeftWithBoxedContent {
            content
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
            video
          }
          ... on WpPage_Blocks_Layouts_ImageWithLeftTopContent {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
            video
          }
          ... on WpPage_Blocks_Layouts_Map {
            content
            fieldGroupName
            title
          }
          ... on WpPage_Blocks_Layouts_ImageLeft {
            content
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_BoxedContentWithImagesReversed {
            fieldGroupName
            content1WithoutToggleOnMobile
            content1
            content2
            afterBoxContent {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
            }
            image1 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image2 {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle1
            subtitle2
            title1
            title2
          }
          ... on WpPage_Blocks_Layouts_TextContentWithLogo {
            content
            fieldGroupName
            backgroundLogoImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            logo {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
          }
          ... on WpPage_Blocks_Layouts_ContentRightWithToggle {
            fieldGroupName
            contentAboveToggle
            contentBelowToggle
            contentBelowBox {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            linksBelowToggle {
              link {
                target
                title
                url
              }
            }
            toggle {
              content
              subtitle
              title
            }
          }
          ... on WpPage_Blocks_Layouts_ContactForm {
            fieldGroupName
            title
            button {
              target
              title
              url
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_JoinOurTeamForm {
            fieldGroupName
            title
            textContent
            button {
              target
              title
              url
            }
          }
          ... on WpPage_Blocks_Layouts_ImageLeftWithSpace {
            content
            fieldGroupName
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
            toggle {
              title
              content
            }
          }
          ... on WpPage_Blocks_Layouts_ImageWithRightTopContent {
            fieldGroupName
            contentAbove {
              content
              subtitle
              title
            }
            contentOnImage {
              content
              subtitle
              title
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_LogoLeft {
            content
            fieldGroupName
            logo {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            title
          }
          ... on WpPage_Blocks_Layouts_ImageRightWithContentToggle {
            content
            fieldGroupName
            contentBelow {
              content
              subtitle
              title
            }
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            subtitle
            title
            toggle {
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_Projects {
            fieldGroupName
            title
          }
          ... on WpPage_Blocks_Layouts_Blog {
            fieldGroupName
            title
          }
        }
      }
    }
    wp {
      themeGeneralSettings {
        themeSettings {
          ctaBoxes {
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            button {
              target
              title
              url
            }
          }
        }
      }
    }
  }
`

export function Head() {
  return (
    <meta name="facebook-domain-verification" content="6u19o5tsx17x3s5y7amggnckop5pse" />
  )
}
