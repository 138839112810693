import React from "react"
import "../../styles/text-content.scss"

export default function TextContent({ data, slug, location }) {
    return (
        <div className="text-content-component-wrapper">
            <img className="logo" src={data?.logo?.localFile?.publicURL} alt={data?.logo?.altText} loading="lazy" />
            {data?.title &&
            <h2 className="title title-xl blue">{data?.title}</h2>
            }
            {data?.subtitle &&
            <h3 className="subtitle">{data?.subtitle}</h3>
            }
            <div className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
            {data?.button &&
            <a className="button" href={data?.button?.url}>{data?.button?.title}</a>
            }


        </div>
    )
}
