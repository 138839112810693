import React from "react"
import "../../styles/image-with-content-middle-left.scss"

export default function ImageWithContentMiddleLeft({ data, slug, location }) {
    return (
        <div className="image-with-content-middle-left-wrapper">
            <div className="image-with-content-middle-left">
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
                <div className="text-content-wrapper">
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title blue title-ml-mobile-small">{data?.title}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                    {data?.button &&
                    <a data-sal="fade"
                       data-sal-duration="1s"
                       data-sal-easing="ease" className="button" href={data?.button?.url} target={data?.button?.target}>{data?.button?.title}</a>
                    }
                </div>
            </div>
        </div>
    )
}
