import React from "react"
import "../../styles/image-left-with-boxed-content.scss"
import ReactPlayer from "react-player";

export default function ImageLeftWithBoxedContent({ data, slug, location }) {
    return (
        <div className="image-left-with-boxed-content-wrapper">
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="media-container">
                {data?.video &&
                <ReactPlayer
                    url={data?.video}
                    width="100%"
                    height="100%"
                    light={data?.image?.localFile?.publicURL}
                />
                }
                {!data?.video &&
                <img src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
                }
            </div>
            <div className="text-content-wrapper">
                <div className="text-content">
                    <h3 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle}</h3>
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title book">{data?.title}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}>
                    </div>
                    {data?.button &&
                    <a data-sal="fade"
                       data-sal-duration="1s"
                       data-sal-easing="ease-in" className="button" href={data?.button?.url}>{data?.button?.title}</a>
                    }
                </div>
            </div>
        </div>
    )
}
