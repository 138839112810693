import React, {useState} from "react"
import "../../styles/image-with-two-box-contents.scss"

export default function ImageWith2BoxContents({ data, slug, location }) {
    var toggleArray = Array(2)
    toggleArray = [true, false]
    const[toggle, setToggle] = useState(toggleArray)

    function toggleContent(index) {
        toggleArray = toggle
        var newToggleItem = !toggleArray[index]
        toggleArray.fill(false)
        toggleArray[index] = newToggleItem
        return setToggle([...toggleArray])
    }

    return (
        <>
            {data?.aboveImageContent?.content &&
                <div className="container">
                    <div className="above-image-content">
                        {data?.aboveImageContent?.title &&
                        <h2 data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="title">{data?.aboveImageContent?.title}</h2>
                        }
                        {data?.aboveImageContent?.subtitle &&
                        <h2 data-sal="fade"
                            data-sal-delay="100"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="subtitle">{data?.aboveImageContent?.subtitle}</h2>
                        }
                        <div data-sal="fade"
                             data-sal-delay="100"
                             data-sal-duration="1s"
                             data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.aboveImageContent?.content}}></div>
                    </div>
                </div>
            }
            <div className="image-with-2-box-contents-wrapper">
                <div className="main-content-wrapper">
                    <img data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
                    <div className="boxes-wrapper">
                        <div className="box-1">
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title desktop">{data?.box1?.title}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description desktop" dangerouslySetInnerHTML={{__html: data?.box1?.content}}></div>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="toggle-mobile">
                                <h2 className="title" onClick={() => toggleContent(0)}>{data?.box1?.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={toggle[0] ? 'minus' : ''}>{toggle[0] ? '-' : '+'}</span></h2>
                                <div className={"description description__toggle " + (toggle[0] ? 'opened' : '')} dangerouslySetInnerHTML={{__html: data?.box1?.content}}></div>
                            </div>
                        </div>
                        <div className="box-2">
                            <h2 data-sal="fade"
                                data-sal-delay="100"
                                data-sal-duration="1s"
                                data-sal-easing="ease-in" className="title desktop">{data?.box2?.title}</h2>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="description desktop" dangerouslySetInnerHTML={{__html: data?.box2?.content}}></div>
                            <div data-sal="fade"
                                 data-sal-delay="100"
                                 data-sal-duration="1s"
                                 data-sal-easing="ease-in" className="toggle-mobile">
                                <h2 className="title" onClick={() => toggleContent(1)}>{data?.box2?.title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={toggle[1] ? 'minus' : ''}>{toggle[1] ? '-' : '+'}</span></h2>
                                <div className={"description description__toggle " + (toggle[1] ? 'opened' : '')} dangerouslySetInnerHTML={{__html: data?.box2?.content}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                {data?.afterImageContent?.content &&
                <div className="after-image-content">
                    {data?.afterImageContent?.title &&
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="subtitle blue">{data?.afterImageContent?.title}</h2>
                    }
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.afterImageContent?.content}}></div>
                </div>
                }
            </div>
        </>
    )
}
