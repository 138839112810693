import React from "react"
import "../../styles/projects.scss"
import ProjectsList from "./ProjectsList";

export default function Projects({ data, slug, location }) {
    console.log('projects', data)
    return (
        <div className="projects-wrapper">
            <h2 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="title">{data?.title}</h2>
            <ProjectsList />
        </div>
    )
}
