import React from "react"
import "../../styles/image-left.scss"

export default function ImageLeft({ data, slug, location }) {
    return (
        <div className="image-left-wrapper">
            <img data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
            <div className="text-content-wrapper">
                <div className="text-content">
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                </div>
            </div>
        </div>
    )
}
