import React, {useState, useEffect, useCallback } from "react"
import "../../styles/form.scss"
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { navigate } from "gatsby"

export default function Form({ form }) {
console.log(form?.confirmations[0]?.type === 'PAGE', 'confirmations')
    const [disabled, setDisabled] = useState(false)
    const [errors, setErrors] = useState(null)
    const [successForm, setSuccessForm] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [reCaptchaFieldId, setReCaptchaFieldId] = useState();

    const handleReCaptchaLoad = useCallback(() => {
      console.log('reCAPTCHA loaded');
    }, []);

    const changeHandler = (event) => {
        const index = parseInt(event.target.dataset.index)
        // 1. Make a shallow copy of the items
        let items = [...selectedFiles];

        items[index] = event.target.files[0];

        console.log(items, 'items')
        // 5. Set the state to our new copy
        setSelectedFiles(items);
    };

    async function handleSubmit(e) {
        e.preventDefault()
        setDisabled(true)

        try {
          // Force reCAPTCHA to get a new token
          if (window.grecaptcha) {
            const newToken = await window.grecaptcha.execute('6Lc6w2ElAAAAAD5lrIJ-Y55MLWIzTP8eUFRRZPHk', {action: 'submit'});
            setReCaptchaToken(newToken);

            // verify recaptcha
            const response = await fetch('https://data.tallen-inc.com/wp-json/custom/v1/verify_recaptcha', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token: reCaptchaToken }),
            });

            const result = await response.json();

            if (response.ok) {
              console.log('reCAPTCHA verified successfully');

              // Proceed with form submission
              let formData = new FormData(e.target)
              // formData.append('g-recaptcha-response', reCaptchaToken);
              // console.log('form data 1', formData)
              // if (selectedFile) {
              //     formData.append('input_8', selectedFile)
              // }
              // console.log('form data 2', formData)
              // const formProps = Object.fromEntries(formData)

              // let postObj = {
              //     form_id: form?.databaseId,
              //     input_values: formProps
              // }

              // let post = JSON.stringify(postObj)
              let post = formData
              post.append('form_id', form?.databaseId)
              console.log('post', post)

              const url = `https://data.tallen-inc.com/wp-json/gforms/v1/submit`
              let xhr = new XMLHttpRequest()

              xhr.open('POST', url, true)
              // xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
              xhr.send(post);

              xhr.onload = function () {
                  const response = JSON.parse(xhr.response)
                  const status = xhr.status
                  let errorMessages = [];

                  if (status === 200) {
                      if (response?.is_valid) {
                          console.log('success')
                          setSuccessForm(true)

                          // check if there is confirmation redirect and redirect
                          if (form?.confirmations[0]?.type === 'PAGE') {
                              navigate('/thank-you/')
                          }

                      } else {
                          console.log('response', response)
                          setDisabled(false)

                          for (let [key, value] of Object.entries(response?.validation_messages)) {
                              errorMessages[key] = value
                          }

                          setErrors(errorMessages)
                      }
                  } else {
                      setDisabled(false)
                      errorMessages.push({status: 'Try again later'})
                  }
              }
            } else {
              setDisabled(false)
              let errorMessages = [];
              setErrors({status: 'reCAPTCHA verification failed. Refresh the page and try again'})
              console.error(errors)
            }
          } else {
            throw new Error('reCAPTCHA not loaded');
          }
        } catch (error) {
          console.error('Error during form submission:', error);
          setDisabled(false)
          setErrors({status: 'An error occurred. Please try again.'})
        }
    }

    useEffect(() => {
      loadReCaptcha('6Lc6w2ElAAAAAD5lrIJ-Y55MLWIzTP8eUFRRZPHk', handleReCaptchaLoad);

      console.log(form,'frrr')

      if (form.databaseId === 1) {
          setReCaptchaFieldId('input_cd00638f3390ae35f984c721a3434f94')
      }

      if (form.databaseId === 4) {
          setReCaptchaFieldId('input_57d0676bd1813fc0035748bcba1617d1')
      }

      if (form.databaseId === 3) {
          setReCaptchaFieldId('input_caf731380c5ac593521ecdbabc01632a')
      }
    }, [handleReCaptchaLoad]);

    const verifyCallback = (recaptchaToken) => {
        if (!recaptchaToken) {
            // disable submit
            setDisabled(true)
            setErrors([{status: 'reCAPTCHA check failed. Refresh the page and try again'}])
        } else {
            setReCaptchaToken(recaptchaToken)
        }
    }



    // const updateToken = () => {
    //     // you will get a new token in verifyCallback
    //     recaptcha.execute();
    // }

    let i = -1

    return (
        <>
        <ReCaptcha
            sitekey="6Lc6w2ElAAAAAD5lrIJ-Y55MLWIzTP8eUFRRZPHk"
            action="submit"
            verifyCallback={verifyCallback}
        />

        {!successForm &&
        <form className="form-component-wrapper" onSubmit={(event) => handleSubmit(event)}>

            {form?.formFields?.nodes?.map(field => {
                const error = (errors && errors[field?.id]) ? errors[field?.id] : ''
                if (field?.type?.toLowerCase() === 'fileupload') {i++}
                return (
                    <div className={`gf_field gf_field_container_${field?.type?.toLowerCase()} ` + field.cssClass}>
                        {error &&
                        <div className="error">{error}</div>
                        }
                        {(field?.type?.toLowerCase() === 'text' || field?.type?.toLowerCase() === 'phone' || field?.type?.toLowerCase() === 'email') &&
                        <input
                            aria-invalid="false"
                            aria-required="true"
                            className={`gravityform__field__input gravityform__field__input__${field?.type?.toLowerCase()} large`}
                            id={`input_${form.databaseId}_${field?.id}`}
                            name={`input_${field?.id}`}
                            placeholder={field?.placeholder}
                            type={field?.type?.toLowerCase()}
                            required={field?.isRequired}
                        />
                        }
                        {(field?.type?.toLowerCase() === 'radio') &&
                        <>
                        <p className="gfield_radio_text">{field?.label}</p>
                        <ul className="gfield_radio" id={`input_${field?.id}`}>
                            {field?.choices?.map((choice, index) => {
                                return (
                                    <li>
                                        <label htmlFor={`input_radio_${field?.id}_${index + 1}`}>
                                            <input
                                                className={`gravityform__field__input gravityform__field__input__${field?.type?.toLowerCase()}`}
                                                id={`input_radio_${field?.id}_${index + 1}`} name={`input_${field?.id}`}
                                                type="radio" value={choice?.value} required={field?.isRequired}/>
                                            <span>{choice?.text}</span>
                                        </label>
                                    </li>
                                )
                            })}
                        </ul>
                        </>
                        }
                        {(field?.type?.toLowerCase() === 'select') &&
                        <select
                            aria-invalid="false"
                            aria-required="true"
                            id={`input_${form.databaseId}_${field?.id}`}
                            name={`input_${field?.id}`}
                            placeholder={field?.placeholder}
                            required={field?.isRequired}
                        >
                            <option value="" selected disabled>{field?.placeholder}</option>
                            {field?.choices?.map((choice) => {
                                return (
                                    <option value={choice?.value}>{choice?.text}</option>
                                )
                            })}
                        </select>
                        }
                        {field?.type?.toLowerCase() === 'textarea' &&
                        <textarea
                            aria-invalid="false"
                            aria-required="true"
                            id={`input_${form.databaseId}_${field?.id}`}
                            name={`input_${field?.id}`}
                            placeholder={field?.placeholder}
                            required={field?.isRequired}
                        >
                                </textarea>
                        }
                        {field?.type?.toLowerCase() === 'fileupload' &&
                            <>
                            <label htmlFor={`input_${form.databaseId}_${field?.id}`}>{(field?.id === 8) ? '*Upload resume' : 'Add optional attachment (Accepted file types: pdf, docx, doc, xlsx, xls, Max. file size: 5 MB)'}</label>
                            <input
                                aria-invalid="false"
                                aria-required="true"
                                data-index={i}
                                onChange={e => changeHandler(e)}
                                className={`gravityform__field__input_file gravityform__field__input__${field?.type?.toLowerCase()}`}
                                id={`input_${form.databaseId}_${field?.id}`}
                                name={`input_${field?.id}`}
                                placeholder={field?.placeholder}
                                type="file"
                                required={field?.isRequired} />
                            <p className="gravityform_file_name">{selectedFiles[i]?.name}</p>
                            </>
                        }
                    </div>
                )
            })}

           {/* <input type="hidden" name={reCaptchaFieldId} value={reCaptchaToken} /> */}

            <button className="button" type="submit" disabled={disabled}>{form?.submitButton?.text}</button>

            {errors?.status &&
                <div className="gf_field">
                    <div className="error">{errors?.status}</div>
                </div>
            }
        </form>
        }
        {successForm &&
        <div className={"success-form-message-block description " + (successForm ? 'form-submitted' : '')} dangerouslySetInnerHTML={{__html: form?.confirmations[0]?.message}}></div>
        }
        </>
    )
}
