import React, {useEffect, useRef, useState} from "react"
import "../../styles/blog-list.scss"
import {graphql, StaticQuery} from "gatsby";
import {DateField} from "gatsby-plugin-gravity-forms/src/components/Input";
import moment from "moment";
import ReactPlayer from "react-player";

export default function BlogList() {
    const showReadMore = Array().fill(false)
    const [readMore, setReadMore] = useState(showReadMore)
    let cardsPerLoad = 6;
    const [currentCardsCount, setCurrentCardsCount] = useState(6)

    React.useEffect(() => {
        if (typeof document !== `undefined`) {
            for (var i = 0; i < document.getElementsByClassName("post-description").length; i++) {
                showReadMore[i] =
                    document.getElementsByClassName("post-description")[i].scrollHeight > document.getElementsByClassName("post-description")[i].clientHeight
            }
            setReadMore([...showReadMore])
        }
    }, [])

    return (
        <StaticQuery
          query={graphql`
            query BlogList {
              allWpPost {
                edges {
                  node {
                    id
                    uri
                    title
                    content
                    date
                    author {
                      node {
                        name
                      }
                    }
                    categories {
                      nodes {
                        name
                      }
                    }
                    blog {
                      fieldGroupName
                      previewcontent
                      subtitle
                      title
                      featuredVideo {
                        mediaItemUrl
                      }
                    }
                    featuredImage {
                      node {
                        altText
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
         `}

          render={data => {
              const posts = data?.allWpPost?.edges

              return (
                  <div className="blog-list-wrapper">
                      <div className="blog-list">
                          {posts && posts?.slice(0, currentCardsCount).map((postItem, index) => {
                              var post = postItem.node
                              var title = (post?.blog?.title) ? post?.blog?.title : post?.title
                              var excerpt = (post?.blog?.previewcontent) ? post?.blog?.previewcontent : post?.content

                              return (
                                  <div className="post-card">
                                      <div className="preview-image-wrapper">
                                          {!post?.blog?.featuredVideo?.mediaItemUrl &&
                                            <a href={post?.uri}><img src={post?.featuredImage?.node?.localFile?.publicURL} alt={post?.featuredImage?.node?.altText} loading="lazy" /></a>
                                          }
                                          {post?.blog?.featuredVideo?.mediaItemUrl &&
                                              <div className="player-wrapper">
                                                  <ReactPlayer
                                                      url={post?.blog?.featuredVideo?.mediaItemUrl}
                                                      width="100%"
                                                      height="100%"
                                                      light={post?.featuredImage?.node?.localFile?.publicURL}
                                                      playing="false"
                                                      className="react-player"
                                                  />
                                              </div>
                                          }
                                      </div>
                                      <div className="text-content-wrapper">
                                          <h2 className="title book"><a href={post?.uri}>{title}</a></h2>
                                          <h3 className="subtitle blue">
                                              Posted By {post?.author?.node?.name} | {moment(post?.date).format("MMMM DD, Y")}
                                          </h3>
                                          <div className="description post-description"
                                               dangerouslySetInnerHTML={{__html: excerpt}}>
                                          </div>
                                          <a href={post?.uri} className="button read-more">Read More</a>
                                      </div>
                                  </div>
                              )
                          })}
                      </div>
                      {(currentCardsCount < posts.length) &&
                      <a data-sal="fade"
                         data-sal-duration="1s"
                         data-sal-easing="ease" className="button load-more" onClick={() => {setCurrentCardsCount(currentCardsCount + cardsPerLoad)}}>Load More</a>
                      }
                  </div>
              )
          }}
        />
    )
}
