import React, { useEffect, useState } from "react"
import "../../styles/two-images-with-contents.scss"
import ReactPlayer from "react-player";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function TwoImagesWithContents({ data, slug, location }) {
    const [videoThumb, setVideoThumb] = useState((data?.image1?.localFile?.publicURL) ? data?.image1?.localFile?.publicURL : true)
    const [autoplay, setAutoplay] = useState(0);

    // const isBrowser = typeof window !== "undefined"
    useEffect(() => {
        const video = document.querySelector('.video-1')
        const isAutoplay = data?.autoplayVideo1

        if (video && isAutoplay) {
            video.addEventListener('sal:in', ({ detail }) => {
                console.log('entering', video)
                setVideoThumb(false)
                setAutoplay(true)
            })
        }
    }, [])

    const image = getImage(data?.image2?.localFile?.childImageSharp?.gatsbyImageData)

    return (
        <div className="two-images-with-contents-wrapper">
            <div className="first-row video-1">
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="media-container">
                    {data?.video1 &&
                        <div className="player-wrapper">
                            <ReactPlayer
                                url={data?.video1}
                                width="100%"
                                height="100%"
                                light={videoThumb}
                                playing="true"
                                className="react-player"
                                muted={autoplay}
                            />
                        </div>
                    }
                </div>
                <div className="text-content-wrapper">
                    {data?.subtitle1 &&
                    <h3 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle1}</h3>
                    }
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title book">{data?.title1}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content1}}></div>
                    <a data-sal="fade"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="button" href={data?.button1?.url} target={data?.button1?.target}>{data?.button1?.title}</a>
                </div>
            </div>
            <div className="second-row">
                <div className="text-content-wrapper">
                    {data?.subtitle2 &&
                    <h3 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="subtitle blue">{data?.subtitle2}</h3>
                    }
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title book">{data?.title2}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content2}}></div>
                    <a data-sal="fade"
                            data-sal-duration="1s"
                            data-sal-easing="ease-in" className="button" href={data?.button2?.url} target={data?.button2?.target}>{data?.button2?.title}</a>
                </div>
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="media-container">
                    {data?.video2 &&
                    <ReactPlayer
                        url={data?.video2}
                        width="100%"
                        height="100%"
                        light={data?.image2?.localFile?.publicURL}
                    />
                    }
                    {!data?.video2 &&
                    <GatsbyImage image={image} alt={data?.image2?.altText} loading="lazy" formats={["webp", "auto"]} />
                    }
                </div>

            </div>
        </div>
    )
}
