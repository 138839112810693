import React, {useState} from "react"
import "../../styles/join-our-team-form.scss"
import Form from "./Form";
import {graphql, useStaticQuery} from "gatsby";

export default function JoinOurTeamForm({ data, slug, location }) {
    const form = useStaticQuery(graphql`
    query joinOUrTeamFormQuery {
      wpGfForm(databaseId: { eq: 4 }) {
        ...GravityFormFields
      }
    }
  `)?.wpGfForm;

    const [showForm, setShowForm] = useState(false)

    console.log('join our team form', data)
    return (
        <div className="join-our-team-form-wrapper">
            <h2 className="title">{data?.title}</h2>
            <p className="description" dangerouslySetInnerHTML={{__html: data?.textContent}}></p>
            {!showForm &&
            <button className="button" onClick={() => setShowForm(true)}>{data?.button?.title}</button>
            }
            {showForm &&
            <Form form={form} />
            }
        </div>
    )
}
