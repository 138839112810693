import React from "react"
import "../../styles/text-content-left-aligned.scss"

export default function TextContentLeftAligned({ data, slug, location }) {
    console.log('text content left aligned ', data)
    return (
        <div className="text-content-left-aligned-component-wrapper">
            {data?.subtitle &&
            <h3 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="subtitle">{data?.subtitle}</h3>
            }
            {data?.title &&
            <h2 data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="1s"
                data-sal-easing="ease-in" className="title blue">{data?.title}</h2>
            }
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
            <a data-sal="fade"
               data-sal-duration="1s"
               data-sal-easing="ease" className="button" href={data?.button?.url}>{data?.button?.title}</a>
        </div>
    )
}
