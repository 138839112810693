import React from "react"
import "../../styles/banner.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Banner({ data, slug, location }) {
  const image = getImage(data?.image?.localFile?.childImageSharp?.gatsbyImageData)

  return (
    <div className="banner-wrapper">
        <GatsbyImage image={image} alt={data?.image?.altText} loading="eager" formats={["webp", "auto"]} />
        <div className="text-content">
            <h3 className="title">{data?.title}</h3>
            <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: data?.subtitle}}></h2>
        </div>
    </div>
  )
}
