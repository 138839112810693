import React from "react"
import "../../styles/image-with-left-top-content.scss"

export default function ImageWithLeftTopContent({ data, slug, location }) {
    return (
        <div className="image-with-left-top-content-wrapper">
            <img data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
            <div className="text-content-wrapper">
                <div className="text-content">
                    <h2 data-sal="fade"
                        data-sal-delay="100"
                        data-sal-duration="1s"
                        data-sal-easing="ease-in" className="title blue">{data?.title}</h2>
                    <div data-sal="fade"
                         data-sal-delay="100"
                         data-sal-duration="1s"
                         data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                    {data?.button &&
                    <a data-sal="fade"
                       data-sal-duration="1s"
                       data-sal-easing="ease" className="button"
                       href={data?.button?.url}>{data?.button?.title}</a>
                    }
                </div>
            </div>
            <a data-sal="fade"
               data-sal-duration="1s"
               data-sal-easing="ease" className="button button-block" href={data?.button?.url}>{data?.button?.title} ></a>
        </div>
    )
}
