import React from "react"
import "../../styles/logo-left.scss"

export default function LogoLeft({ data, slug, location }) {
    return (
        <div className="logo-left-wrapper">
            <div className="logo-wrapper">
                <img data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" src={data?.logo?.localFile?.publicURL} alt={data?.logo?.altText} loading="lazy" />
            </div>
            <div className="text-content-wrapper">
                <h2 data-sal="fade"
                    data-sal-delay="100"
                    data-sal-duration="1s"
                    data-sal-easing="ease-in" className="title book title-ml-mobile-small title-mobile-l">{data?.title}</h2>
                <div data-sal="fade"
                     data-sal-delay="100"
                     data-sal-duration="1s"
                     data-sal-easing="ease-in" className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
                {data?.button &&
                    <a data-sal="fade"
                       data-sal-duration="1s"
                       data-sal-easing="ease" className="button"
                       href={data?.button?.url}>{data?.button?.title}</a>
                }
            </div>
        </div>
    )
}
