import React, {useRef, useState} from "react"
import "../../styles/contact-form.scss"
import {graphql, useStaticQuery} from "gatsby";
import Form from "./Form";

export default function ContactForm({ data, slug, location }) {
    const form = useStaticQuery(graphql`
    query contactFormQuery {
      wpGfForm(databaseId: { eq: 1 }) {
        ...GravityFormFields
      }
    }
  `)?.wpGfForm;

    return (
        <div className="contact-form-wrapper">
            <img src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
            <div className="form-wrapper">
                <h2 className="title">{data?.title}</h2>
                <a className="number" href={data?.button?.url}>{data?.button?.title}</a>
                <Form form={form} />
            </div>
        </div>
    )
}
